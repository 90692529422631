<template>
  <div class="check">
    <PageTitle title="消息"
               backTo="/home"
               fixed
               :leftArrow="false"></PageTitle>
    <List v-if="show" style="margin-top:1rem">
    </List>
    <List v-if="show">
      <ListItem :leftWidth="50"
                :rightWidth="50"
                :to="'/systemmessage/'+ckhm">
        <template v-slot:top>
          <p style="text-align: center;">系统消息</p>
          <div class="borderColor">
            <van-icon :badge="item.unReadNum"
                      style="float: right;"
                      v-if="item.unReadNum!='0'"/>
            <div class="leftArea"
                 v-if="lock==true"
                 style="padding-top: 5px">
              <p class="fontmd"
                 style="float: left;">{{ item.detail[0].msg.title }}</p>
              <p class="fontmd"
                 style="float: right;">{{ GetSJ(item.detail[0].sendDate) }}</p>
              <p class="fontmd"
                 style="clear: both;font-size: 12px">{{ item.detail[0].msg.content }}</p>
            </div>
          </div>
        </template>
        <!-- 输入任意文本 -->
      </ListItem>

      <ListItem :leftWidth="50"
                :rightWidth="50">
        <template v-slot:bottom>
          <p style="text-align: center;">我的咨询</p>

          <div class="borderColor"
               style="margin-top: 10px"
               v-for="(item,index) in ZX"
               :key="index"
               @click="TZ(item.targetId,item.applyInfoId,item.avatarUrl, item.startDate)">

            <van-icon :badge="item.unReadNum"
                      style="float: right"
                      v-if="item.unReadNum!='0'"/>
            <div class="leftArea"
                 v-if="lock==true">

              <img :src="item.avatarUrl || defaultDoctorPicture"
                   style="height:44px;width:44px;border-radius: 50%;float: left">
              <p class="fontmd"
                 style="float: left;margin-left: 5px">{{ item.doctorName }}-{{item.consultType}}</p>
              <p class="fontsm"
                 style="float: left;margin-top: 3px;margin-left: 2px">{{ item.doctorLevel }}</p>
              <p class="fontsm"
                 style="float: right;margin-left: 2px;margin-top: 3px">{{ GetSJ(item.sendDate) }}</p>
              <p class="fontmd"
                 style="margin-left: 50px; margin-top: 30px;color: #76B49C; ">{{ item.msg }}</p>

            </div>
          </div>
        </template>

        <!-- 输入任意文本 -->
      </ListItem>
    </List>
    <van-empty v-if="!show" description="暂无数据" />
  </div>
</template>

<script>
// @ is an alias to /src
//  import MainFrame from '@/components/home-page/MainFrames.vue';
//  import List from '@/components/home-page/List.vue';
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';
import {GetXTXX, GetZX} from '@/api/check.js';

import {getDefaultAccount} from '@/api/user.js';

export default {
  name: 'Message',
  components: {
    PageTitle,
    //        MainFrame,
    List,
    ListItem,
  },
  data() {
    return {
      isDestroyed: false,
      item: [
        {
          msg: {},
          sendDate: '',
        }
      ],
      ckhm: "",
      ZX: "",
      targetId: '',
      lock: false,
      ApplyInfoID: '',
      i: 0,
      heartbeatInstance: null,
      socket: null,
      show: false,
      defaultDoctorPicture: require('../assets/default_doctor.png'),
    }
  },
  methods: {
    TZ(e, f, h, i) {
      //                this.$router.push('/consult/'+e+'/'+f);
      this.$router.replace({path: '/consult/' + e + '/' + f, query: {image: h, startDate: i}});
      localStorage.setItem('payOpenIds', "");
      localStorage.setItem('address1', "");
      localStorage.setItem('address2', "");
    },
    heartbeat() {
      this.socket.send(JSON.stringify({
        msg: '',
        msgtype: "6",
        senderid: 'patient-' + this.ckhm,
        targetId: -1,
        id: ''
      }));
    },
    doConnect(app) {
      let that = this;

      if (this.$store.SaveSocket && this.$store.SaveSocket.readyState == WebSocket.OPEN) {
        this.socket = this.$store.SaveSocket;
      } else {
        let uri = process.env.VUE_APP_API_WSS_URL + '/ws?senderId=patient-' + this.ckhm + '&targetId=-1'
        this.socket = new WebSocket(uri);
        this.$store.SaveSocket = this.socket;//webscoket实例 存进全局
      }

      this.socket.onopen = function () {
        that.heartbeatInstance = setInterval(() => {
          if (that.socket && that.socket.readyState == WebSocket.OPEN) {
            that.heartbeat();
          } else {
            if (that.heartbeatInstance) {
              clearInterval(that.heartbeatInstance);
            }
          }
        }, 50000, that)
      };

      this.socket.onclose = function () {
        if (!that.isDestroyed) {
          setTimeout(() => {
            that.doConnect(that);
          }, 5000, that)
        }
      };

      this.socket.onmessage = function (e) {
        let msg = JSON.parse(e.data);
        if (msg.SenderId == '-1' && msg.MsgType != "6") {
          app.item.unReadNum = msg.totalUnReadNum > 99 ? "99+" : msg.totalUnReadNum;
          app.item.detail[0].msg.title = JSON.parse(msg.Msg).title;
          app.item.detail[0].sendDate = msg.SendDate
          app.item.detail[0].msg.content = JSON.parse(msg.Msg).content;
        } else {
          for (let m = 0; m < app.ZX.length; m++) {
            if (msg.SenderId == app.ZX[m].targetId) {
              app.ZX[m].unReadNum = msg.unReadNum > 99 ? "99+" : msg.unReadNum;
              app.ZX[m].msg = msg.Msg
              app.ZX[m].sendDate = msg.SendDate
            }
          }
        }
      }
    },
    GetSJ(e) {
      if (e != null) {
        if (this.$util.moment(e).format('YYYY-MM-DD') == this.$util.moment().format('YYYY-MM-DD')) {
          return this.$util.moment(e).format('HH:mm')
        } else {
          return this.$util.moment(e).format('YYYY-MM-DD')
        }
      }
    },

    creats() {
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
          (result) => {
            this.ckhm = result.patientId;
            this.doConnect(this);
            GetXTXX('patient-' + this.ckhm, 1, 1).then(
                (result) => {
                  this.item = result || [{
                    msg: {},
                    sendDate: null,
                  }];
                  this.lock = true;
                  //                                this.item[0].sendDate= this.$util.moment(this.item[0].sendDate).format('YYYY-MM-DD');
                },
                () => {
                }
            );
            GetZX(this.ckhm).then(
                (results) => {
                  this.ZX = results;
                  this.show = true;
                  //                             this.ZX[0].sendDate= this.$util.moment(this.item[0].sendDate).format('YYYY-MM-DD');
                },
                () => {
                  this.show = false;
                }
            )
          },
          () => {
          }
      );

    }
  },

  created: function () {
    this.creats();
  },
  destroyed: function () {
    if (this.heartbeatInstance) {
      clearInterval(this.heartbeatInstance)
    }

    if (this.socket) {
      this.isDestroyed = true;
      this.socket.close();
    }
  },
}

</script>

<style scoped="" lang="less">
@import "../style/var.less";

.div-mt {
  margin-top: 10px;
}

.width-half {
  div {
    width: 50%;
    display: inline-block;
  }

  div:nth-child(2n) p {
    float: right;
    color: #ff2600;
  }

  div:nth-child(2n + 1) p {
    float: left;
  }
}

.infoContainer {
  padding: 10px;

  p {
    margin: 0;
  }

  .top p {
    text-align: left;
    margin: 0 0 10px;
  }

  .right {
    p {
      font-size: @font-size-sm;
    }
  }
}

.borderColor {
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 10px;
  margin: 0 10px;

  .leftArea {
    width: 100%;
    display: inline-block;
  }

  .rightArea {
    width: 15%;
    display: inline-block;
    vertical-align: top;

    p {
      float: right;
    }

    .van-icon {
      float: right;
      margin-top: 40px;
    }
  }
}

.fontmd {
  font-size: @font-size-md;
}

.fontsm {
  font-size: @font-size-sm;
}

p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
